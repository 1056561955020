<template>
  <div>
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 12" :key="n" cols="4">
       <v-sheet class="mt-2 px-3">
        <v-skeleton-loader class="mx-auto" type="image" height="75"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <div v-else class="px-0 mt-3">
      <!-- <v-card flat tile>
        <template v-for="(item, index) in commonList">
          <v-row class="mx-0 px-2 custom_border_color_style" :key="`${item.id}_${index}_items`">
            <template v-for="(navItem, index) in item.list">
              <v-col cols="4" class="pa-0" :class="`${setBorderRight(index + 1, item.list)} ${setBorderBottom(index + 1, item.list)}`" :key="`${navItem._id}_module_${index}`">
                <v-card tile flat :to="navItem.to" class="pa-0">
                  <div class="text-center pt-1">
                    <v-avatar>
                      <v-icon color="grey darken-3" size="33">{{ navItem.icon ? navItem.icon : 'mdi-cube'}}</v-icon>
                    </v-avatar>
                  </div>
                  <v-card-text class="text-center pa-2 font-weight-medium">{{navItem.isStatic ? $t(navItem.name) : navItem.name}}</v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </template>
      </v-card> -->
      <!-- new tiles feature enabled 1/0 based list -->
      <v-card flat tile>
        <template v-for="(item, index) in tilesList">
          <v-row class="mx-0 px-2" :class="item.list.length > 3 ? 'custom_border_color_style' : ''" :key="`${item.id}_${index}_items`">
            <template v-for="(navItem, index) in item.list">
              <v-col cols="4" class="pa-0" :class="`${setBorderRight(index + 1, item.list)} ${setBorderBottom(index + 1, item.list)}`" :key="`${navItem.to}_module_${index}`">
                <v-card tile flat :to="navItem.to" @click="navItem.isCustomModules ? openInNewTab(navItem) : null" class="pa-0">
                  <div class="text-center pt-1">
                    <v-avatar>
                      <v-icon color="grey darken-3" size="33">{{ navItem.icon ? navItem.icon : 'mdi-cube'}}</v-icon>
                    </v-avatar>
                  </div>
                  <v-card-text class="text-center pa-2 font-weight-medium">{{navItem.isStatic ? $t(navItem.name) : navItem.name}}</v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </template>
        <v-row >
          <v-col cols="4" class="ml-1 grid-border-right">
            <v-card tile flat @click="scanning">
              <div class="text-center pt-1">
                <v-avatar>
                  <v-icon color="grey darken-3" size="33">mdi-qrcode-scan</v-icon>
                </v-avatar>
              </div>
              <v-card-text class="text-center pa-2 font-weight-medium">{{ $t('message.timer.scanQRCode') }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
     <!-- SCAN BAR CODE -->
    <v-dialog v-model="scanDialog" persistent max-width="500px" class="mt-0">
      <v-card>
        <v-card-title>
          <!-- {{ $t(scannerPayload.placeholder) }} -->{{$t('message.common.scan')}}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          {{ scanError }}
            <div>
              <video id="video" style="border: 1px solid gray; width:100%; height:auto;" ref="video"></video>
            </div>
            <div id="sourceSelectPanel" v-if="videoInputDevices.length > 1">
              <v-select id="sourceSelect" v-model="selectedDeviceId" outlined :items="videoInputDevices" hide-details item-text="label" item-value="deviceId" :label="$t('message.common.changeVideoSource')"></v-select>
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="scanClose" @click="stopScanningSession">{{ $t('message.common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showProjectSelectDialog" persistent max-width="500px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('message.projects.selectProject') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          <v-sheet v-if="isProjectsByUsedProductLoading" class="d-flex justify-center pa-5">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-sheet>
            <!-- :item-text="item => `${item.po_ranked} - ${item.project_name} - ${item.ao_ranked} - ${item.work_order_name}`" -->
          <v-autocomplete
            v-else
            v-model="seletedProject"
            :items="selectableProjectForPickup"
            :item-text="item => `${item.po_ranked ? item.po_ranked : ''} ${item.project_name ? ' - ' + item.project_name : ''} ${item.ao_ranked ? ' - ' +  item.ao_ranked : ''} ${item.work_order_name ? ' - ' + item.work_order_name : ''}`"
            item-value="timelogapp_id"
            :label="$t('message.projects.selectProject')"
            @input="gotoPickup(seletedProject)"
            menu-props="auto, overflowY"
            outlined
            dense
          >
          </v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="handleProjectSelection">{{ $t('message.common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { BrowserMultiFormatReader } from '@zxing/library'
import PermissionsMixin from '@/mixins/permissions'
import FeaturesMixin from '@/mixins/features_list'
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'

export default {
  mixins: [PermissionsMixin, FeaturesMixin],
  data () {
    return {
      allTiles: {
        stemp: { icon: 'mdi-timer', to: '/stemp', name: 'message.layout.stemp' },
        deviations: { icon: 'mdi-leak', to: '/deviations', name: 'message.layout.deviation' },
        projects: { icon: 'mdi-note-multiple-outline', to: '/projects', name: 'message.layout.projects' },
        calendar: { icon: 'mdi-calendar', to: '/calendar', name: 'message.layout.calendar' },
        files: { icon: 'mdi-file', to: '/files', name: 'message.layout.files' },
        checklists: { icon: 'mdi-book-plus', to: '/global_checklist', name: 'message.layout.checklists' },
        // checklist_files: { icon: 'mdi-file-document-multiple', to: '/checklist_files', name: 'Checklist files' },
        equipments: { icon: 'mdi-developer-board', to: '/equipments', name: 'message.layout.equipments' },
        warehouse: { icon: 'mdi-warehouse', to: '/warehouse', name: 'message.layout.warehouse' }
      },
      stempItem: { _id: 'static_item1', icon: 'mdi-timer', to: '/stemp', isStatic: true, name: 'message.layout.stemp' },
      deviationItem: { _id: 'static_item2', icon: 'mdi-leak', to: '/deviations', isStatic: true, name: 'message.layout.deviation' },
      projectItem: { _id: 'static_item3', icon: 'mdi-note-multiple-outline', to: '/projects', isStatic: true, name: 'message.layout.projects' },
      calendarItem: { _id: 'static_item4', icon: 'mdi-calendar', to: '/calendar', isStatic: true, name: 'message.layout.calendar' },
      fileItem: { _id: 'static_item5', icon: 'mdi-file', to: '/files', isStatic: true, name: 'message.layout.files' },
      checklistItem: { _id: 'static_item6', icon: 'mdi-book-plus', to: '/global_checklist', isStatic: true, name: 'message.layout.checklists' },
      equipmentItem: { _id: 'static_item7', icon: 'mdi-developer-board', to: '/equipments', isStatic: true, name: 'message.layout.equipments' },
      warehouseItem: { _id: 'static_item8', icon: 'mdi-warehouse', to: '/warehouse', isStatic: true, name: 'message.layout.warehouse' },
      fieldsLoading: false,
      reloadApp: false,
      searchTerm: '',
      scanDialog: false,
      scanError: '',
      codeReader: {},
      videoInputDevices: [],
      selectedDeviceId: window.localStorage.getItem('WelditPwa_storeCamera') ? window.localStorage.getItem('WelditPwa_storeCamera') : 0,
      // debouncedSearchBarcode: null
      showProjectSelectDialog: false,
      selectableProjectForPickup: [],
      isProjectsByUsedProductLoading: false,
      selectedProductCode: null,
      seletedProject: null
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getNewContentAvailable', 'timerData', 'getuserDetails', 'getHostRefApi', 'userId']),
    commonList () {
      const navItems = []
      if (!this.$store.state.common.isStempExcluded) navItems.push(this.stempItem)
      if (!this.$store.state.common.isDeviationExcluded) navItems.push(this.deviationItem)
      if (!this.$store.state.common.isProjectExcluded) navItems.push(this.projectItem)
      navItems.push(this.calendarItem)
      navItems.push(this.fileItem)
      if (this.$store.state.common.includeGlobalChecklist) navItems.push(this.checklistItem)
      if (!this.$store.state.common.isEquipmentExcluded) {
        if (this.checkActionPermission(this.CONSTANTS.equipment, this.CONSTANTS.LIST)) navItems.push(this.equipmentItem)
      }
      if (!this.$store.state.common.isWarehouseExcluded) {
        if (this.checkFeatureEnabled('warehouse') && this.checkActionPermission(this.CONSTANTS.warehouse, this.CONSTANTS.LIST)) navItems.push(this.warehouseItem)
      }
      return [
        { id: 'navigationItems', list: navItems }
      ]
    },
    tilesList () {
      let navItems = []
      Object.keys(this.allTiles).forEach(key => {
        var checklistFiles = ''
        if (key === 'checklist_files') {
          key = 'checklists'
          checklistFiles = 'checklist_files'
        } else checklistFiles = ''
        const featureObj = this.getFeatureObj(key)
        if (featureObj && featureObj.enabled && ([this.CONSTANTS.equipments, this.CONSTANTS.warehouse].includes(key) ? this.checkActionPermission(this.CONSTANTS[key], this.CONSTANTS.LIST) : true)) { // 3rd on check for the permission if has acces to equipemnt or warehouse
          /* if (['equipments', 'warehouse'].includes(key)) {
            if (key === 'equipments') if (this.checkActionPermission(this.CONSTANTS.equipment, this.CONSTANTS.LIST)) navItems.push(this.allTiles[key])
            if (key === 'warehouse') if (this.checkActionPermission(this.CONSTANTS.warehouse, this.CONSTANTS.LIST)) navItems.push(this.allTiles[key])
            return
          } */
          key = checklistFiles === 'checklist_files' ? 'checklist_files' : key
          this.allTiles[key].name = checklistFiles === 'checklist_files' ? 'Checklist files' : featureObj.label
          navItems.push(this.allTiles[key])
        }
      })
      // get custom modules and add with navItems
      const customModules = this.getCustomModules()
      if (customModules) navItems = [...navItems, ...customModules]
      return [
        { id: 'navigationItems', list: navItems }
      ]
    }
  },
  async beforeRouteEnter (to, from, next) {
    await next(vm => {
      const reloadedFromCode = window.localStorage.getItem('reloadedFromCode') || 0
      if (!Number(reloadedFromCode) && from && (['/login', '/'].includes(from.path))) vm.reloadApp = true
    })
  },
  created () {
    this.fieldsLoading = false
    this.$store.dispatch('getGeneralSettings')
    this.updateFeaturesData()
  },
  mounted () {
    window.localStorage.removeItem('reloadedFromCode')
  },
  methods: {
    openInNewTab (cModule) {
      if (cModule.link) window.open(cModule.link, '_blank')
    },
    setBorderRight (itemPosition, list) {
      // const isNotLastItem = itemPosition < list.length
      return (itemPosition % 3) !== 0 ? 'grid-border-right' : '' // && isNotLastItem add after !== 0
    },
    setBorderBottom (itemPosition, list) {
      const noOf3s = Math.floor(list.length / 3)
      const remainingNos = list.length % 3
      if (remainingNos) {
        return itemPosition <= (noOf3s * 3) ? 'grid-border-bottom' : ''
      } else {
        let nextRow = [0, 1].includes(remainingNos) ? 3 : 1
        if (remainingNos === 2) nextRow = 2
        return (itemPosition + nextRow) <= (noOf3s * 3) ? 'grid-border-bottom' : ''
      }
    },
    scanning (module) {
      const self = this
      this.scanDialog = true
      self.scanError = ''
      self.scanDialog = true
      // const selected = window.localStorage.getItem('WelditPwa_storeCamera')
      self.codeReader = new BrowserMultiFormatReader()
      self.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        self.videoInputDevices = videoInputDevices
        /* if (selected && selected !== 'undefined' && selected !== null) {
          self.selectedDeviceId = selected
        } else { */
        self.selectedDeviceId = this.$vuetify.breakpoint.mobile && videoInputDevices.length > 1 ? videoInputDevices[1].deviceId : videoInputDevices[0].deviceId
        // }
        this.scanFromVideoSource(self.selectedDeviceId, module)
      }).catch((err) => {
        console.log(err)
      })
    },
    scanFromVideoSource (videoDeviceId, module) {
      if (videoDeviceId) window.localStorage.setItem('WelditPwa_storeCamera', videoDeviceId)
      this.codeReader.decodeOnceFromVideoDevice(videoDeviceId, 'video')
        .then((result) => {
          this.searchTerm = result.text
          this.stopScanningSession()
          setTimeout(() => {
            this.getRespectiveValue()
          }, 50)
        }).catch((err) => {
          if (err && err.name && err.name === 'NotAllowedError') alert(this.$t('message.common.permissionDenied'))
        })
    },
    stopScanningSession () {
      this.scanDialog = false
      this.codeReader.reset()
      this.codeReader.stopContinuousDecode()
      this.codeReader = {}
    },
    getRespectiveValue () {
      var result = {}
      var qr = ''
      // this.searchTerm.split('?')[1].split('&').map(x => {
      //   result[x.split('=')[0]] = x.split('=')[1]
      // })
      // if (result.is_pr && result.tid) {
      //   if (result.mid === 'deviation') result.mid = 'deviations'
      //   else if (result.mid === 'project-checklist') result.mid = 'checklists'
      //   this.$router.push(`/projects/${result.tid}/${result.mid}`)
      // } else if (result.mid === 'equipment') {
      //   // this.$router.push(`/equipments/${result.oid}`)
      //   this.$router.push(`/equipments/${result.o}`)
      // } else if (result.mid === 'product') {
      //   // var qr = this.searchTerm.split('?')[0].split('/')
      //   qr = this.searchTerm.split('?')[0].split('/')
      //   this.$router.push(`/product_view?qr=${qr[qr.length - 1].replace('&', '-')}`)
      // } else if (result.i && result.t) {
      //   if (result.m === 'deviation') result.m = 'deviations'
      //   else if (result.m === 'project-checklist') result.m = 'checklists'
      //   this.$router.push(`/projects/${result.t}/${result.m}`)
      // } else if (result.m === 'equipment') {
      //   this.$router.push(`/equipments/${result.o}`)
      // } else if (result.m === 'product') {
      //   qr = this.searchTerm.split('?')[0].split('/')
      //   this.$router.push(`/product_view?qr=${qr[qr.length - 1].replace('&', '-')}`)
      // if (this.searchTerm.match(/i=.*o=.*m=.*t=/)) {
      if (this.searchTerm.match(/o=.*m=/) || this.searchTerm.match(/oid=.mid=/)) {
        this.searchTerm.split('?')[1].split('&').map(x => {
          result[x.split('=')[0]] = x.split('=')[1]
        })
      }
      try {
        if (result !== {} && result.is_pr && result.tid) {
          if (result.mid === 'deviation') result.mid = 'deviations'
          else if (result.mid === 'project-checklist') result.mid = 'checklists'
          this.$router.push(`/projects/${result.tid}/${result.mid}`)
        } else if (result.mid === 'equipment') {
          this.$router.push(`/equipments/${result.oid}`)
        // } else if (result.mid === 'product') {
        //   qr = this.searchTerm.split('?')[0].split('/')
        //   this.$router.push(`/product_view?qr=${qr[qr.length - 1].replace('&', '-')}`)
        } else if (result.m === 'product' || result.m === 'warehouseorder') {
          this.selectedProductCode = this.searchTerm.split('?')[0].slice(1)
          this.showProjectSelectDialog = true
          this.getProjectsByUsedProduct()
        } else if (result.i && result.t) {
          if (result.m === 'deviation') result.m = 'deviations'
          else if (result.m === 'project-checklist') result.m = 'checklists'
          // this.$router.push(`/projects/${result.t}/${result.m}`)
          // this.$router.push(`/projects/${result.t}/custom_modules/29?name=MAY%2019-01`)
          if (result.c) this.$router.push(`/projects/${result.t}/custom_modules/${result.c}?name=${result.m}`)
          else this.$router.push(`/projects/${result.t}/${result.m}`)
        } else if (result.m === 'equipment') {
          this.$router.push(`/equipments/${result.o}`)
        } else if (result.m === 'product') {
          qr = this.searchTerm.split('?')[0].split('/')
          this.$router.push(`/product_view?qr=${qr[qr.length - 1].replace('&', '-')}`)
        } else if (result.m) {
          qr = this.searchTerm.split('?')[0].split('/')
          this.$router.push(`/custom_modules?qr=${qr[qr.length - 1].replace('&', '-')}`)
        } else {
          this.$router.push('/not_found')
        }
      } catch (e) {
        this.$router.push('/not_found')
      }
    },
    updateFeaturesData () {
      this.$i18n.locale = window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) ? window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) : 'no'
      const authToken = this.$cookie.get(process.env.VUE_APP_TOKEN)
      if (authToken) {
        hostAppApi.get(`${this.getHostRefApi}check_and_get_tenant_features?user_id=${this.userId}`, { headers: { language: this.$i18n.locale } })
          .then((response) => {
            if (response && response.data) {
              if (response.data.features) {
                const features = response.data.features
                if (features) this.features = features
              }
            }
          })
      }
    },
    getProjectsByUsedProduct () {
      this.isProjectsByUsedProductLoading = true
      hostAppApi.get(`${this.getHostRefApi}get_active_projects?is_pwa_warehouse=1`)
        .then((response) => {
          this.selectableProjectForPickup = response.data
        })
        .catch()
        .finally(() => { this.isProjectsByUsedProductLoading = false })
    },
    gotoPickup (id) {
      this.showProjectSelectDialog = false
      this.$router.push(`projects/${id}/materials/pick/${this.selectedProductCode}`)
    },
    handleProjectSelection () {
      this.showProjectSelectDialog = false
      this.$router.push(`/product_view?qr=${this.selectedProductCode.replace('&', '-')}`)
    }
  },
  watch: {
    reloadApp (val) {
      if (val) {
        this.$router.go()
        window.localStorage.setItem('reloadedFromCode', 1)
      }
    },
    selectedDeviceId (val) {
      if (val) {
        window.localStorage.setItem('WelditPwa_storeCamera', val)
        this.scanFromVideoSource(val, this.module)
      }
    },
    '$i18n.locale' (val) {
      if (val) this.updateFeaturesData()
    }
  }
}
</script>
<style>
.dashboard_bg_color {
  /* background: #578ebe; */
  background: linear-gradient(180deg, grey 85%, #ffffff 15%);
  border-radius: 0 !important;
}
.grid-border-bottom {
  border-bottom: 0.85px solid #19191912 !important;
}
.grid-border-right {
  border-right: 0.85px solid #19191912 !important;
}
.custom-margin {
  margin-top: 28%;
}
.custom_bg_color {
  background: grey !important;
}
.custom_border_color_style {
  /* border: 15px solid #578ebe; */
  border-bottom: none;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  min-height: 30vh !important;
}
</style>
